import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <br />
    <h4>{`1. Locate your desired trail on trailguide.net`}</h4>
    <img className="mb-20 rounded shadow-md border border-gray-500" src={img("/howto/garmin-import-1-locate-trail-1.jpg")} />
    <h4>{`2. Click on the trail on the map and then click on download`}</h4>
    <img className="mb-8 rounded shadow-md border border-gray-500" src={img("/howto/garmin-import-2-download-1.jpg")} />
    <img className="mb-20 rounded shadow-md border border-gray-500" src={img("/howto/garmin-import-2-download-2.jpg")} />
    <h4>{`3. Locate the downloaded GPX file on your PC/Mac`}</h4>
    <h4>{`4. Plug your Garmin device into your PC/Mac and locate the Garmin drive`}</h4>
    <img className="mb-20 rounded shadow-md border border-gray-500" src={img("/howto/garmin-import-3-garmin-1.jpg")} />
    <h4>{`5. Click on "Garmin" then "New Files"`}</h4>
    <h4>{`6. Drop the downloaded GPX file into the "New Files" folder`}</h4>
    <img className="mb-20 rounded shadow-md border border-gray-500" src={img("/howto/garmin-import-3-garmin-5.jpg")} />
    <h4>{`7. Unplug your Garmin device`}</h4>
    <h4>{`8. When you start your Garmin Device, you can find the trail under "Saved Courses"`}</h4>
    <p className="my-16">
  Under settings, you can also select "always show" to burn the trail to the map
  so it’s easier to follow. This can be done with multiple trails so you’ll have
  several options. They can also be colour coded for easy separation.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      